<template>
    <div id="app">
        <Sidebar v-if="authUser && authUser.npa" />
        <SidebarPic v-if="autPic" />

        <router-view></router-view>
        <div class="container-fluid" v-if="authUser && authUser.npa">
            <div class="page-content">
                <footer class="text-muted" style="">
                    <!-- Section: Social media -->
                    <section class="d-flex ">
                        <!-- Left -->
                        <div class="me-5 d-lg-block">
                            <span>Hubungi Kami </span><a href="https://nutrimet.id/contact/" target="_blank">Disini</a> <span> untuk pertanyaan lebih lanjut</span>
                            <p>Dengan masukan subject : "Recopedia"</p>
                        </div>
                        <!-- Left -->
                    </section>
                    <!-- Section: Social media -->
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar';
import SidebarPic from '@/components/SidebarPic';
import { mapGetters } from 'vuex';

export default {
    name: 'app',
    data() {
        return {
            is_script_loading: false,
        };
    },
    components: {
        Sidebar,
        SidebarPic,
    },
    beforeMount() {},
    created() {
        // let jqueryScript = document.createElement("script");
        // jqueryScript.setAttribute("src","https://cdn.jsdelivr.net/jquery/latest/jquery.min.js");
        // jqueryScript.defer  = true;
        // let momentScript = document.createElement("script");
        // momentScript.setAttribute("src","https://cdn.jsdelivr.net/momentjs/latest/moment.min.js");
        // momentScript.defer = true;
        // let daterangeScript = document.createElement("script");
        // daterangeScript.setAttribute("src","https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js");
        // daterangeScript.defer = true;
        // document.body.appendChild(jqueryScript)
        // document.body.appendChild(momentScript)
        // document.body.appendChild(daterangeScript)
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
        ...mapGetters('pic', ['autPic']),
    },
    methods: {},
};
</script>

<style>
@import url('https://use.fontawesome.com/releases/v5.15.4/css/all.css');
</style>
