import * as API from '@/services/API';
import store from '@/store';

export default {
    async getPasien(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
            params: payload.params,
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.get('/api/pasien', headers);
    },
    async detailPasien(id) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.get(`/api/pasien/${id}`, headers);
    },
    async addPasien(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.post('/api/pasien', payload, headers);
    },
    async updatePasien(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.put('/api/pasien', payload, headers);
    },
    async getHistory(payload, id) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
            params: payload.params,
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.get(`/api/pasien/${id}/history`, headers);
    },
    async deletePasien(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
            data: payload.data,
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.delete(`/api/pasien`, headers, payload);
    },
    async updateUser(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
            data: payload.data,
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.post('/api/user/update', payload, headers);
    },
    async getPost(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
            params: payload.params,
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.get(`/api/posts`, headers);
    },
    async detailPost(id) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.get(`/api/pasien/${id}`, headers);
    },
    async getKalkulator(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.post(`/api/kalkulator`, payload, headers);
    },
    async getSusu(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
            params: payload.params,
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.get(`/api/susu`, headers);
    },
    async sendWa(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await API.apiClient.get('/sanctum/csrf-cookie');
        return API.apiClient.post(`/api/kalkulator/send`, payload, headers);
    },
};
