import { getError } from '@/utils/helpers';
import DokterService from '@/services/DokterService';

export const namespaced = true;

export const state = {
    data: [],
    meta: null,
    links: null,
    loading: false,
    error: null,
};

export const mutations = {
    SET_DATA(state, data) {
        state.data = data;
    },
    SET_META(state, meta) {
        state.meta = meta;
    },
    SET_LINKS(state, links) {
        state.links = links;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    async getPasien({ commit }, payload) {
        commit('SET_LOADING', true);
        try {
            const response = await DokterService.getPasien(payload);
            // commit("SET_DATA", response.data.user);
            commit('SET_LOADING', false);
            return response.data.data;
        } catch (error) {
            commit('SET_LOADING', false);
            commit('SET_DATA', null);
            commit('SET_ERROR', getError(error));
        }

        //   await DokterService.getPasien(payload)
        //     .then((response) => setResponse(commit, response) )
        //     .catch((error) => {
        //       commit("SET_LOADING", false);
        //       commit("SET_ERROR", getError(error));
        //     });
    },
    async addPasien({ commit }, payload) {
        commit('SET_LOADING', true);
        await DokterService.addPasien(payload)
            .then((response) => setResponse(commit, response))
            .catch((error) => {
                commit('SET_LOADING', false);
                commit('SET_ERROR', getError(error));
            });
    },
};

function setResponse(commit, response) {
    commit('SET_DATA', response.data.data);
    commit('SET_LOADING', false);
}

export const getters = {
    data: (state) => {
        return state.data;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};
