import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import * as auth from '@/store/modules/Auth';
import * as user from '@/store/modules/User';
import * as message from '@/store/modules/Message';
import * as dokter from '@/store/modules/Dokter';
import * as pic from '@/store/modules/Pic';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,

    modules: {
        auth,
        user,
        message,
        dokter,
        pic,
    },

    plugins: [createPersistedState()],
});
