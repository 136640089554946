import axios from 'axios';
import store from '@/store';

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true, // required to handle the CSRF token
});
/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters['pic/token']
        ) {
            store.dispatch('pic/logout');
        }
        return Promise.reject(error);
    }
);

export default {
    async login(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/pic', payload);
    },
    async logout() {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('api/auth/logout', {}, headers);
    },
    async getUser() {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.get('/api/pic/profile', headers);
    },
    async getDokter(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
            params:payload.params,
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.get('/api/dokter', headers);
    },
    async getDashboard(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/dashboard/pic',payload, headers);
    },
    async updateUser(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/pic/update', payload,headers);
    },
    async updateUserImage(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
                "Content-Type":"multipart/form-data"
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/pic/update', payload,headers);
    },
    async updateUserPassword(payload) {
      const headers = {
          headers: {
              Authorization: store.getters['pic/getToken'],
          },
      };
      await authClient.get('/sanctum/csrf-cookie');
      return authClient.post('/api/pic/change_password', payload,headers);
  },
  async detailDokter(id) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.get(`/api/dokter/${id}`, headers);
  },
  async getPasien(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
            params:payload.params
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.get(`/api/dokter/${payload.id}/pasien`, headers);
  },
  async detailPasien(payload) {
    const headers = {
        headers: {
            Authorization: store.getters['pic/getToken'],
        },
    };
    await authClient.get('/sanctum/csrf-cookie');
    return authClient.get(`/api/dokter/${payload.dokter}/pasien/${payload.pasien}`, headers);
  },
  async getHistory(payload) {
    const headers = {
        headers: {
            Authorization: store.getters['pic/getToken'],
        },
        params: payload.params,
    };
    await authClient.get('/sanctum/csrf-cookie');
    return authClient.get(`/api/dokter/${payload.dokter}/pasien/${payload.pasien}/history`, headers);
 },
 async getSusu(payload) {
    const headers = {
        headers: {
            Authorization: store.getters['pic/getToken'],
        },
        params:payload.params
    };
    await authClient.get('/sanctum/csrf-cookie');
    return authClient.get(`/api/susu`, headers);
 },
async sendWa(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['pic/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post(`/api/kalkulator/send`, payload, headers);
},
};
