<template>
    <div>
        <!-- SIDEBAR DESKTOP -->
        <div class="nav-sidebar bg-dark d-none d-md-block">
            <div class="logo-container">
                <img src="../assets/images/logo-recopedia.png" alt="logo.png" />
            </div>
            <div class="doc-profile">
                <div class="row align-items-center">
                    <div class="col-md-4">
                        <div class="avatar-placeholder">
                            <img
                                :src="autPic.avatar"
                                alt="doc.png"
                                class="rounded-circle"
                            />
                        </div>
                    </div>
                    <div class="col-md-8 px-0 pt-1">
                        <div class="doc-stats">
                            <div id="name">{{ autPic.name }}</div>
                            <!-- <div id="no-profesi">321/SKL/231/2021</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="menu-list" id="parent-li">
                <div class="main-menu">
                    <h6 class="menu-head ps-3">MENU</h6>
                    <ul>
                        <template v-for="item in picMenu">
                            <router-link
                                :to="item.link"
                                :key="item.id"
                                @click.native="onListenLink($event, item.id)"
                            >
                                <li
                                    class="menu"
                                    v-bind:class="[
                                        item.id == menuActive
                                            ? 'active-menu'
                                            : '',
                                    ]"
                                >
                                    <i :class="item.icon"></i>
                                    {{ item.name }}
                                </li>
                            </router-link>
                        </template>
                    </ul>
                </div>
                <div class="support-menu">
                    <h6 class="menu-head ps-3">BANTUAN</h6>
                    <ul>
                       <router-link to="/pic/profile"
                                @click.native="onListenLink($event, 'profile')">
                            <li class="menu" 
                            v-bind:class="[
                                        'profile' == menuActive
                                            ? 'active-menu'
                                            : '',
                                ]">
                                <i class="fas fa-user-friends"></i>
                                Profil
                            </li>
                         </router-link>
                        <!-- <a href="https://www.id.abbott/" target="_blank">
                            <li class="menu">
                                <i class="fas fa-cog"></i>
                                Abbott Indonesia
                            </li>
                        </a> -->
                        <a v-on:click="onLogout()">
                            <li class="menu">
                                <i v-if="loadLogout" class="fa fa-spinner fa-spin"></i>
                                <i v-else class="fas fa-power-off"></i>
                                <span>
                                    Logout
                                </span>
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
            <div class="sidebar-footer">
                <p class="m-0 p-0">
                     <!-- <img src="../assets/images/anhi-logo-OG.png" style="width:150px;"/> -->
                </p>
            </div>
        </div>
        <!-- END SIDEBAR DESKTOP -->
        <nav>
            <div class="navbar">
                <img
                    src="../assets/images/logo-recopedia.png"
                    alt="logo.png"
                />
                <a href="#nav" v-on:click="onShowMenuMobile()">MENU 
                    
                    <i v-if="!showMenuMobile" class="fas fa-bars"></i>
                    <i v-else class="fas fa-times"></i>
                </a>
            </div>
            <div class="navbar-menu"  v-bind:class="[showMenuMobile ? 'nav-show' : '']">
                <ul>
                   <template v-for="item in picMenu">
                        <router-link
                            :to="item.link"
                            :key="item.id"
                            @click.native="onListenLink($event, item.id)"
                        >
                            <li
                                class="menu"
                                v-bind:class="[
                                    item.id == menuActive ? 'active-menu' : '',
                                ]"
                            >
                                <i :class="item.icon"></i>
                                 <span
                                    >
                                {{ item.name }}
                                 </span>
                            </li>
                        </router-link>
                    </template>
                     <router-link to="/pic/profile"
                      @click.native="onListenLink($event, 'profile')">
                        <li class="menu"                          
                        v-bind:class="[
                                'profile' == menuActive
                                    ? 'active-menu'
                                    : '',
                                ]">
                            <i class="fas fa-user-circle"></i>
                            <span >Profil</span>
                        </li>
                    </router-link>
                    <!-- <a href="https://www.id.abbott/" target="_blank">
                        <li class="menu">
                            <i class="fas fa-cog"></i>
                            <span>
                            Abbott Indonesia
                            </span>
                        </li>
                    </a> -->
                    <a v-on:click="onLogout()">
                        <li class="menu">
                            <i v-if="loadLogout" class="fa fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-power-off"></i>
                            <span>
                                Logout
                            </span>
                        </li>
                    </a>
                </ul>
            </div>
        </nav>
        <!-- SIDEBAR MOBILE -->

        <div
            class="nav-sidebar-mobile d-block d-sm-block d-md-none"
            id="sidebar-mobile"
            v-bind:style="{
                width: menuWidth,
                scrollBehavior: 'auto',
            }"
        >
            <div class="p-3" 
            v-bind:class="[isExpand ? 'd-none' : '']"
            id="expand-btn">
                <button
                    type="button"
                    class="btn"
                    id="btn-expand-sidebar"
                    v-on:click="showNav()"
                >
                    <i
                        class="fas fa-bars"
                        style="font-size: 28px; color: #9e9e9e"
                    ></i>
                </button>
            </div>
            <div class="doc-profile" id="doc-profile"
            v-bind:style="{ display: isShowProfile }">
                <div class="row align-items-center">
                    <div class="col-3">
                        <router-link to="/pic/profile" @click.native="onListenLink($event, 'profile')">
                            <div class="avatar-placeholder">
                                <img
                                    :src="autPic.avatar"
                                    alt="doc.png"
                                    class="img-fluid"
                                />
                            </div>
                        </router-link>
                    </div>
                    <div class="col-9 px-0 pt-1">
                        <div
                            class="
                                doc-stats
                                d-flex
                                justify-content-evenly
                                align-items-center
                            "
                        >
                            <router-link to="/pic/profile" @click.native="onListenLink($event, 'profile')">
                                <div>
                                    <div id="name" class="text-nowrap">
                                        {{ autPic.name }}
                                    </div>
                                    <!-- <div id="no-profesi" class="fw-bold">321/SKL/231/2021</div> -->
                                </div>
                            </router-link>
                            <button
                                type="button"
                                class="btn"
                                v-on:click="closeNav()"
                                id="btn-toggle-sidebar"
                            >
                                <i
                                    class="fas fa-times"
                                    style="font-size: 28px; color: #9e9e9e"
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-menu">
                <ul>
                    <template v-for="item in picMenu">
                        <router-link
                            :to="item.link"
                            :key="item.id"
                            @click.native="onListenLink($event, item.id)"
                        >
                            <li
                                class="menu"
                                v-bind:class="[
                                    item.id == menuActive ? 'active-menu' : '',
                                ]"
                            >
                                <i :class="item.icon"></i>
                                 <span
                                    v-bind:class="[isExpand ? '' : 'd-none']">
                                {{ item.name }}
                                 </span>
                            </li>
                        </router-link>
                    </template>
                </ul>
            </div>
            <div class="support-menu">
                <h6 class="ps-3 mb-4" id="support-title"
                  v-bind:class="[isExpand ? '' : 'd-none']">BANTUAN</h6>
                <ul>
                    <router-link to="/pic/profile"
                      @click.native="onListenLink($event, 'profile')">
                        <li class="menu"                          
                        v-bind:class="[
                                'profile' == menuActive
                                    ? 'active-menu'
                                    : '',
                                ]">
                            <i class="fas fa-user-circle"></i>
                            <span v-bind:class="[isExpand ? '' : 'd-none']">Profil</span>
                        </li>
                    </router-link>
                    <a v-on:click="onLogout()">
                        <li class="menu">
                            <i v-if="loadLogout" class="fa fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-power-off"></i>
                            <span>
                                Logout
                            </span>
                        </li>
                    </a>
                </ul>
            </div>
        </div>
        <!-- END SIDEBAR MOBILE -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Sidebarpic',
    data() {
        return {
            menuWidth: '80px',
            isShowProfile: 'none',
            isExpand: false,
            loadLogout:false,
            showMenuMobile:false,
            menuActive: '',
            picMenu: [
                {
                    id: 'dashboard',
                    name: 'Dashboard',
                    link: '/pic/dashboard',
                    icon: 'fas fa-columns',
                },
                {
                    id: 'dokter',
                    name: 'Dokter',
                    link: '/pic/dokter',
                    icon: 'far fa-user',
                },
            ],
        };
    },
    props: ['menuName'],
    model: {
        prop: 'menuName',
        event: 'change',
    },
    mounted() {
        this.menuActive = this.$store.getters['pic/getMenuActive'];
    },
    methods: {
        async onLogout() {
            this.loadLogout = true
            await this.$store.dispatch('pic/logout');
            document.body.setAttribute('id', '');
            this.loadLogout = false
        },
        onListenLink(event, id) {
            this.showMenuMobile = false;
            this.$store.dispatch('pic/setMenuActive', { value: id });
            this.menuActive = this.$store.getters['pic/getMenuActive'];
            const width = window.innerWidth;
            if (width < 768) {
                this.closeNav()
            }
       },
        onShowMenuMobile() {
            this.showMenuMobile = this.showMenuMobile ? false : true;
        },
        showNav() {
            // this.menuWidth = '280px';
            // this.gloabalMarginLeft = '280px';
            // this.$emit('width_content', '280px');
            // this.isShowProfile = 'block';
            // this.isExpand = true;
            // this.computeMarginLeft = '280px';

            // var pageContent = document.getElementById('page-content');
            // var pageContentClass =
            //     document.getElementsByClassName('page-content');
            // pageContent.style.marginLeft = '0px';
            // // pageContent.classList.push("moved")
            // for (var i = 0; i < pageContentClass.length; i++) {
            //     pageContentClass[i].className += ' moved';
            // }
        },
        closeNav() {
            // this.menuWidth = '80px';
            // this.isShowProfile = 'none';
            // this.isExpand = false;
            // this.$emit('width_content', '80px');
            // this.gloabalMarginLeft = '80px';
            // this.computeMarginLeft = '80px';
            // var pageContent = document.getElementById('page-content');
            // var pageContentClass =
            //     document.getElementsByClassName('page-content');
            // pageContent.style.marginLeft = '80px';
            // pageContentClass[0].className = 'page-content pic-page';
        },
    },
    watch: {
        menuName(value) {
            this.menuActive = value;
        },
    },
    computed: {
        ...mapGetters('pic', ['autPic']),
    },
};
</script>
<style scoped>
.doc-profile a {
    text-decoration: none;
}
.doc-stats a {
    text-decoration: none;
    color: #2b3674;
}
</style>
