export default function guest({ next, store }) {
    if (store.getters['auth/token']) {
        if (store.getters['auth/authUser']) {
            next({ name: 'dashboard' });
        }  else {
            next();
        }
    } else {
        if(store.getters['pic/token']){
            next({ name: 'pic_dashboard' });
        } else {
            next();
        }
    }
}
