<template>
    <div>
        <div class="nav-sidebar bg-dark d-none d-md-block">
            <div class="logo-container">
                <img
                    src="../assets/images/logo-recopedia.png"
                    alt="logo.png"
                />
            </div>
                <div class="doc-profile">
                    <router-link to="/profile" @click.native="onListenLink($event, 'profile')">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="avatar-placeholder">
                                    <img
                                        :src="authUser.avatar"
                                        alt="doc.png"
                                        class="img-fluid rounded-circle"
                                    />
                                </div>
                            </div>
                            <div class="col-md-8 px-0 pt-1">
                                <div class="doc-stats">
                                    <div id="name">{{ authUser.name }}</div>
                                    <div id="no-profesi">{{ authUser.npa }}</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            <div class="menu-list" id="parent-li">
                <div class="main-menu">
                    <h6 class="menu-head ps-3">MENU</h6>
                    <ul>
                        <template v-for="item in doctorMenu">
                            <router-link
                                :to="item.link"
                                :key="item.id"
                                @click.native="onListenLink($event, item.id)"
                            >
                                <li
                                    class="menu"
                                    v-bind:class="[
                                        item.id == menuActive
                                            ? 'active-menu'
                                            : '',
                                    ]"
                                >
                                    <i :class="item.icon"></i>
                                    {{ item.name }}
                                </li>
                            </router-link>
                        </template>
                       
                    </ul>
                </div>
                <div class="support-menu">
                    <h6 class="menu-head ps-3">BANTUAN</h6>
                    <ul>
                         <router-link to="/profile"
                                @click.native="onListenLink($event, 'profile')">
                            <li class="menu"
                                v-bind:class="[
                                        'profile' == menuActive
                                            ? 'active-menu'
                                            : '',
                                ]">
                                <i class="fas fa-user-circle"></i>
                                Profil
                            </li>
                          </router-link>
                            <a v-on:click="onLogout()">
                                <li class="menu">
                                            <i v-if="loadLogout" class="fa fa-spinner fa-spin"></i>
                                            <i v-else class="fas fa-power-off"></i>
                                            <span>
                                                Logout
                                            </span>
                                </li>
                            </a>

                        <!-- <a href="https://www.id.abbott/" target="_blank">
                            <li class="menu">
                                <i class="fas fa-cog"></i>
                                Abbott Indonesia
                            </li>
                        </a> -->
                       
                    </ul>
                </div>
            </div>
            <div class="sidebar-footer">
                <p class="m-0 p-0">
                     <!-- <img src="../assets/images/anhi-logo-OG.png" style="width:150px;"/> -->
                </p>
            </div>
        </div>
        <nav>
            <div class="navbar">
                <img
                    src="../assets/images/logo-recopedia.png"
                    alt="logo.png"
                />
                <a href="#nav" v-on:click="onShowMenuMobile()">MENU 
                    
                    <i v-if="!showMenuMobile" class="fas fa-bars"></i>
                    <i v-else class="fas fa-times"></i>
                </a>
            </div>
            <div class="navbar-menu"  v-bind:class="[showMenuMobile ? 'nav-show' : '']">
                <ul>
                    <template v-for="item in doctorMenu">
                        <router-link
                            :to="item.link"
                            :key="item.id"
                            @click.native="onListenLink($event, item.id)"
                        >
                            <li
                                class="menu"
                                v-bind:class="[
                                    item.id == menuActive ? 'active-menu' : '',
                                ]"
                            >
                                <i :class="item.icon"></i>
                                <span>{{ item.name }}</span>
                            </li>
                        </router-link>
                    </template>
                     <router-link to="/profile"
                        @click.native="onListenLink($event, 'profile')">
                        <li class="menu"
                         v-bind:class="[
                                'profile' == menuActive
                                    ? 'active-menu'
                                    : '',
                                ]">
                            <i class="fas fa-user-circle"></i>
                            <span
                                >Profil</span
                            >
                        </li>
                    </router-link>
                     <router-link to="/login" @click.native="onLogout()">
                        <li class="menu">
                            <i v-if="loadLogout" class="fa fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-power-off"></i>
                            <span>
                                Logout
                            </span>
                        </li>
                    </router-link>
                    <!-- <a href="https://www.id.abbott/" target="_blank">
                        <li class="menu">
                            <i class="fas fa-cog"></i>
                            <span
                                >Abbott Indonesia</span
                            >
                        </li>
                    </a> -->
                </ul>
            </div>
        </nav>
        <div
            class="nav-sidebar-mobile d-block d-sm-block d-md-none"
            id="sidebar-mobile"
            v-bind:style="{
                width: menuWidth,
                scrollBehavior: 'auto',
            }"
        >
            <div
                class="p-3"
                v-bind:class="[isExpand ? 'd-none' : '']"
                id="expand-btn"
            >
                <button
                    type="button"
                    class="btn"
                    id="btn-expand-sidebar"
                    v-on:click="showNav()"
                >
                    <i
                        class="fas fa-bars"
                        style="font-size: 28px; color: #9e9e9e"
                    ></i>
                </button>
            </div>
            <div
                class="doc-profile"
                id="doc-profile"
                v-bind:style="{ display: isShowProfile }"
            >
                <div class="row align-items-center">
                    <div class="col-3">
                        <router-link to="/profile" @click.native="onListenLink($event, 'profile')">
                            <div class="avatar-placeholder">
                                <img
                                    :src="authUser.avatar"
                                    alt="doc.png"
                                    class="img-fluid"
                                />
                            </div>
                        </router-link>
                    </div>
                    <div class="col-9 px-0 pt-1">
                        <div class="doc-stats d-flex justify-content-evenly">
                            <router-link to="/profile" @click.native="onListenLink($event, 'profile')">
                                <div>
                                    <div id="name" class="text-nowrap">
                                        {{ authUser.name }}
                                    </div>
                                    <div id="no-profesi" class="fw-bold">
                                        {{ authUser.npa }}
                                    </div>
                                </div>
                            </router-link>
                            <button
                                type="button"
                                class="btn"
                                id="btn-toggle-sidebar"
                                v-on:click="closeNav()"
                            >
                                <i
                                    class="fas fa-times"
                                    style="font-size: 28px; color: #9e9e9e"
                                ></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-menu">
                <ul>
                    <template v-for="item in doctorMenu">
                        <router-link
                            :to="item.link"
                            :key="item.id"
                            @click.native="onListenLink($event, item.id)"
                        >
                            <li
                                class="menu"
                                v-bind:class="[
                                    item.id == menuActive ? 'active-menu' : '',
                                ]"
                            >
                                <i :class="item.icon"></i>
                                <span
                                    v-bind:class="[isExpand ? '' : 'd-none']"
                                    >{{ item.name }}</span
                                >
                            </li>
                        </router-link>
                    </template>
                </ul>
            </div>
            <div class="support-menu">
                <h6
                    class="ps-3 mb-4"
                    id="support-title"
                    v-bind:class="[isExpand ? '' : 'd-none']"
                >
                    BANTUAN
                </h6>
                <ul>
                    <router-link to="/profile"
                        @click.native="onListenLink($event, 'profile')">
                        <li class="menu"
                         v-bind:class="[
                                'profile' == menuActive
                                    ? 'active-menu'
                                    : '',
                                ]">
                            <i class="fas fa-user-circle"></i>
                            <span v-bind:class="[isExpand ? '' : 'd-none']"
                                >Profil</span
                            >
                        </li>
                    </router-link>
                    <!-- <a href="https://www.id.abbott/" target="_blank">
                        <li class="menu">
                            <i class="fas fa-cog"></i>
                            <span v-bind:class="[isExpand ? '' : 'd-none']"
                                >Abbott Indonesia</span
                            >
                        </li>
                    </a> -->
                    <a v-on:click="onLogout()">
                        <li class="menu">
                            <i v-if="loadLogout" class="fa fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-power-off"></i>
                            <span>
                                Logout
                            </span>
                        </li>
                    </a>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            menuActive: '',
            menuWidth: '80px',
            isShowProfile: 'none',
            isExpand: false,
            loadLogout:false,
            showMenuMobile:false,
            doctorMenu: [
                {
                    id: 'dashboard',
                    name: 'Dashboard',
                    link: '/dashboard',
                    icon: 'fas fa-columns',
                },
                {
                    id: 'pasien',
                    name: 'Pasien',
                    link: '/pasien',
                    icon: 'far fa-user',
                },
                {
                    id: 'kalkulator',
                    name: 'Kalkulator',
                    link: '/kalkulator',
                    icon: 'fas fa-calculator',
                },
                // {
                //     id: 'video',
                //     name: 'Video',
                //     link: '/video',
                //     icon: 'fab fa-youtube',
                // },
                // {
                //     id: 'artikel',
                //     name: 'Artikel',
                //     link: '/artikel',
                //     icon: 'fab fa-readme',
                // },
            ],
        };
    },
    name: 'Sidebar',
    props: ['menuName'],
    model: {
        prop: 'menuName',
        event: 'change',
    },
    created() {
        this.menuActive = this.$store.getters['auth/getMenuActive'];
    },
    methods: {
        async onLogout() {
            this.loadLogout = true
            await this.$store.dispatch('auth/logout');
            await this.$gAuth.signOut();
            this.loadLogout = false
        },
        onListenLink(event, id) {
            this.showMenuMobile = false;
            this.$store.dispatch('auth/setMenuActive', { value: id });
            this.menuActive = this.$store.getters['auth/getMenuActive'];
            const width = window.innerWidth;
            if (width < 768) {
                this.closeNav()
            }

        },
        onShowMenuMobile() {
            this.showMenuMobile = this.showMenuMobile ? false : true;
        },
        showNav() {
            // this.menuWidth = '280px';
            // this.gloabalMarginLeft = '280px';
            // this.$emit('width_content', '280px');
            // this.isShowProfile = 'block';
            // this.isExpand = true;
            // this.computeMarginLeft = '280px';

            // var pageContent = document.getElementById('page-content');
            // var pageContentClass =
            //     document.getElementsByClassName('page-content');
            // pageContent.style.marginLeft = '0px';
            // // pageContent.classList.push("moved")
            // for (var i = 0; i < pageContentClass.length; i++) {
            //     pageContentClass[i].className += ' moved';
            // }
        },
        closeNav() {
            // this.menuWidth = '80px';
            // this.isShowProfile = 'none';
            // this.isExpand = false;
            // this.$emit('width_content', '80px');
            // this.gloabalMarginLeft = '80px';
            // this.computeMarginLeft = '80px';
            // var pageContent = document.getElementById('page-content');
            // var pageContentClass =
            //     document.getElementsByClassName('page-content');
            // pageContent.style.marginLeft = '80px';
            // pageContentClass[0].className = 'page-content';
        },
    },
    watch: {
        menuName(value) {
            this.menuActive = value;
        },
    },
    computed: {
        ...mapGetters('auth', ['authUser']),
    },
};
</script>
<style scoped>
.doc-profile a {
    text-decoration: none;
}
.doc-stats a {
    text-decoration: none;
    color: #2b3674;
}
</style>