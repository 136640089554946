import axios from 'axios';
import store from '@/store';

export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true, // required to handle the CSRF token
});
/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (
            error.response &&
            [401, 419].includes(error.response.status) &&
            store.getters['auth/token']
        ) {
            store.dispatch('auth/logout');
        }
        return Promise.reject(error);
    }
);

export default {
    async login(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/dokter', payload);
    },
    async loginSosmed(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/dokter/social', payload);
    },
    async register(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/register', payload);
    },
    async registerSosmed(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/register_social', payload);
    },
    async logout() {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('api/auth/logout', {}, headers);
    },
    async getDashboard(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('api/dashboard/dokter', payload, headers);
    },
    async forgotPassword(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/reset_password', payload);
    },
    getAuthUser() {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        return authClient.get('/api/user/profile', headers);
    },

    async resetPassword(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/auth/change_password', payload);
    },
    updatePassword(payload) {
        return authClient.put('/user/password', payload);
    },
    async registerUser(payload) {
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/register', payload);
    },
    sendVerification(payload) {
        return authClient.post('/email/verification-notification', payload);
    },
    async updateUser(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/user/update', payload,headers);
    },
    async updateUserImage(payload) {
        const headers = {
            headers: {
                Authorization: store.getters['auth/getToken'],
                "Content-Type":"multipart/form-data"
            },
        };
        await authClient.get('/sanctum/csrf-cookie');
        return authClient.post('/api/user/update', payload,headers);
    },
    async updateUserPassword(payload) {
      const headers = {
          headers: {
              Authorization: store.getters['auth/getToken'],
          },
      };
      await authClient.get('/sanctum/csrf-cookie');
      return authClient.post('/api/user/change_password_account', payload,headers);
  },
};
