import Vue from 'vue';
import store from '@/store/index';
import VueRouter from 'vue-router';
import auth from '@/middleware/auth';
import pic from '@/middleware/pic';
import dokter_confirm from '@/middleware/dokter_confirm';
// import admin from "@/middleware/admin";
import guest from '@/middleware/guest';
import middlewarePipeline from '@/router/middlewarePipeline';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/Dashboard'),
    },
    {
        path: '/confirm',
        name: 'confirm',
        meta: { middleware: [dokter_confirm] },
        component: () =>
            import(/* webpackChunkName: "home" */ '../views/RegisterConfirm'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    },
    {
        path: '/pasien',
        name: 'pasien',
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "user" */ '../views/Pasien'),
    },
    {
        path: '/pasien/detail/:id',
        name: 'pasien_detail',
        params: true,
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "user" */ '../views/DetailPasien'),
    },
    {
        path: '/kalkulator',
        name: 'kalkulator',
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "users" */ '../views/Kalkulator'),
    },
    {
        path: '/video',
        name: 'video',
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "users" */ '../views/Video'),
    },
    {
        path: '/artikel',
        name: 'artikel',
        meta: { middleware: [auth] },
        component: () =>
            import(/* webpackChunkName: "users" */ '../views/Artikel'),
    },
    {
      path: '/profile',
      name: 'profile',
      meta: { middleware: [auth] },
      component: () =>
          import(/* webpackChunkName: "users" */ '../views/Profile'),
    },
    {
        path: '/login',
        name: 'login',
        meta: { middleware: [guest] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/Login'),
    },
    {
        path: '/pic/login',
        name: 'pic_login',
        meta: { middleware: [guest] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/PicLogin'),
    },
    {
        path: '/pic/dashboard',
        name: 'pic_dashboard',
        meta: { middleware: [pic] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/PicDashboard'),
    },
    {
        path: '/pic/dokter',
        name: 'pic_dokter',
        meta: { middleware: [pic] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/PicDokter'),
    },
    {
        path: '/pic/dokter/detail/:id',
        name: 'pic_dokter_detail',
        params:true,
        meta: { middleware: [pic] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/DetailDokter'),
    },
    {
        path: '/pic/dokter/detail/:id/pasien/:pasien',
        name: 'pic_dokter_pasien_detail',
        params:true,
        meta: { middleware: [pic] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/PicDetailPasien'),
    },
    {
        path: '/pic/profile',
        name: 'pic_profile',
        meta: { middleware: [pic] },
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/PicProfile'),
    },
    {
        path: '/register',
        name: 'register',
        meta: { middleware: [guest] },
        component: () =>
            import(/* webpackChunkName: "register" */ '../views/Register'),
    },
    {
        path: '/reset-password/:token',
        name: 'resetPassword',
        params: true,
        meta: { middleware: [guest] },
        component: () =>
            import(
                /* webpackChunkName: "reset-password" */ '../views/ResetPassword'
            ),
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        meta: { middleware: [guest] },
        component: () =>
            import(
                /* webpackChunkName: "forgot-password" */ '../views/ForgotPassword'
            ),
    },
    {
        path: '/:catchAll(.*)',
        name: 'notFound',
        component: () =>
            import(/* webpackChunkName: "not-found" */ '../views/NotFound'),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    if (!middleware) {
        return next();
    }

    middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    });
});

export default router;
