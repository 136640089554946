export default function auth({ to, next, store }) {
    const loginQuery = { path: '/login', query: { redirect: to.fullPath } };
    if (!store.getters['auth/token']) {
        next(loginQuery);
    } else {
        if (!store.getters['auth/npa']) {
            next({ name: 'confirm' });
        } else {
            next();
        }
    }
}
