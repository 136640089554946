import router from '@/router';
import { getError } from '@/utils/helpers';
import PicService from '@/services/PicService';

export const namespaced = true;

export const state = {
    user: null,
    token: null,
    email: null,
    loading: false,
    menuActive: 'dashboard',
    message: '',
    error: null,
};

export const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_MENU(state, menu) {
        state.menuActive = menu;
    },
    SET_TOKEN(state, token) {
        state.token = token;
    },
    SET_EMAIL(state, email) {
        state.email = email;
    },
    SET_MESSAGE(state, message) {
        state.message = message;
    },
    SET_LOADING(state, loading) {
        state.loading = loading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
};

export const actions = {
    logout({ commit }) {
        return PicService.logout()
            .then(() => {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_EMAIL', null);
                if (router.currentRoute.name !== 'pic_login') {
                    router.push({ path: '/pic/login' });
                }
            })
            .catch((error) => {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
                commit('SET_EMAIL', null);
                if (router.currentRoute.name !== 'pic_login') {
                    router.push({ path: '/pic/login' });
                }
                commit('SET_ERROR', getError(error));
            });
    },
    async getAuthUser({ commit }) {
        commit('SET_LOADING', true);
        try {
            const response = await PicService.getUser();
            commit('SET_USER', response.data.user);
            commit('SET_LOADING', false);
            return response.data.user;
        } catch (error) {
            commit('SET_LOADING', false);
            commit('SET_USER', null);
            commit('SET_ERROR', getError(error));
        }
    },
    setMenuActive(context, { value }) {
        context.commit('SET_MENU', value);
    },
    setToken(context, { value }) {
        context.commit('SET_TOKEN', value);
    },
};

export const getters = {
    autPic: (state) => {
        return state.user;
    },
    getMenuActive: (state) => {
        return state.menuActive;
    },
    isDokter: (state) => {
        return state.user ? state.user.isAdmin : false;
    },
    token: (state) => {
        if (state.token) {
            return true;
        } else {
            return false;
        }
    },
    getToken: (state) => {
        return 'Bearer ' + state.token;
    },
    getEmail: (state) => {
        return state.email;
    },
    getMessage: (state) => {
        return state.message;
    },
    error: (state) => {
        return state.error;
    },
    loading: (state) => {
        return state.loading;
    },
    loggedIn: (state) => {
        return !!state.user;
    },
    guest: () => {
        const storageItem = window.localStorage.getItem('guest');
        if (!storageItem) return false;
        if (storageItem === 'isGuest') return true;
        if (storageItem === 'isNotGuest') return false;
    },
};
